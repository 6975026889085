<script setup>
const windowType = 'BASIC' // '['BASIC', 'OPTIONS']'
</script>

<template>
  <div class="hk-ck-holder">
    <div class="position-relative w-100 h-100">
      
      <div :class="`hk-ck-icon-holder d-flex align-items-end justify-content-${flexPosition}`">
        <div class="p-4" @click="toggleWindow">
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5"></path>
            <path d="M8.5 8.5v.01"></path>
            <path d="M16 15.5v.01"></path>
            <path d="M12 12v.01"></path>
            <path d="M11 17v.01"></path>
            <path d="M7 14v.01"></path>
          </svg>
        </div>
      </div>

      <div v-if="expanded" :class="`position-absolute hk-ck-window-holder w-100 d-flex align-items-end justify-content-${flexPosition}`">
        <div class="p-4">
          
          <template v-if="windowType === 'BASIC'">
            <div class="hk-ck-win-basic p-3">
              <div class="hk-ck-title hk-ck-underline">Cookies policy</div>
              <div class="hk-ck-text my-2">This site uses cookies for technical purposes.</div>
              <div class="hk-ck-note">That's all...</div>
              <div class="d-flex align-items-center justify-content-end mt-1">
                <button @click="submitBasicCookie">Okay</button>
              </div>
            </div>
          </template>

          <template v-else>
          </template>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    toggleWindow() {
      this.expanded = !this.expanded
    },
    submitBasicCookie() {


      this.expanded = false
    },
  },
  props: {
    position: {
      typeof: String,
      required: false,
    },
  },
  computed: {
    flexPosition() {
      return this.position ? this.position : 'end'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import 'bootstrap/scss/bootstrap';

  .hk-ck-holder {
    position: fixed;
    z-index: 6000;
    width: 100vw;
    height: 100dvh;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .hk-ck-icon-holder {
    height: inherit;
    min-height: inherit;
    
    > div {
      pointer-events: all;
      cursor: pointer;
    }
    
    svg {
      width: 40px;
      height: 40px;
      stroke: $gray-300;
      transition: all 0.2s;
      filter: drop-shadow(2px 2px 5px $gray-600);
    }
    
    &:hover {
      svg {
        stroke: $accent_main;
        transition: all 0.2s;
        filter: drop-shadow(2px 2px 3px $gray-900);
      }
    }
  }

  .hk-ck-window-holder {
    top: 0;
    height: inherit;
    min-height: inherit;
    pointer-events: all;

    .hk-ck-win-basic {
      border-radius: 10px;
      background-color: $background_main;
      max-width: 450px;
      box-shadow: 4px 4px 20px $black;
    }

    .hk-ck-title {
      color: $accent_main;
      font-size: 20px;
      font-family: Syne;
      font-weight: 900;
    }
    .hk-ck-underline {
      border-bottom: 1px solid $accent_main;
    }
    .hk-ck-note {
      color: $gray-500;
      font-style: italic;
      font-size: 14px;
    }
  }

  button {
    background-color: transparent;
    border: 2px solid $accent_main;
    border-radius: 6px;
    padding: 5px 11px;
    font-size: 18px;
    color: $accent_main;
    transition: all 0.2s;

    &:hover {
      background-color: $accent-main;
      color: $black;
      transition: all 0.2s;
    }
    
    &:active {
      border: 2px solid $white;
      color: $white;
    }
  }
</style>
